<template>
    <div class="curation_container" :class="{ dim: !timeCurationStep1 }">
        <confirm-comp
            id="genreComp"
            :text1="'선택하신 장르로 큐레이션할 경우,'"
            :text2="'분위기가 일관되지 않을 수 있습니다.'"
            :text3="'선택하신 장르로 계속 하시겠습니까?'"
            v-if="isGenreComp"
            @close-modal="onCloseGenreModal"
            @confirm-modal="onConfirmGenreModal"
        />
        <vocal-modal
            v-if="isVocalModal"
            :generobject="genreObject"
            @close-vocalmodal="onCloseVocalModal"
            @confirm-vocal="onConfirmVocal"
        ></vocal-modal>
        <alert-modal
            v-if="isOverGenreModal"
            :alertText="'선호장르는 최대 3개까지 선택가능합니다.'"
            @closeModal="onCloseOverGenreModal"
        />
        <!-- ################## 듀토리얼 Dim ################## -->
        <div class="tutorial_dim" v-if="!timeCurationStep1">
            <div class="dim_curation_wrap all">
                <div class="tab_body">
                    <div class="cuarion_time_wrap white dp_flex flex_space_between">
                        <h2 class="curation_time_title">큐레이션 설정</h2>
                        <button class="curation_time_btn dim">
                            시간대별 설정 &nbsp;
                            <img
                                class="dp_inlineblock curation_time_arrow"
                                src="/media/img/curation/arrow_forward.png"
                                alt="시간별설정"
                            />
                        </button>
                        <div class="tour_box">
                            <img class="arrow_top" src="/media/img/curation/polygon.png" alt="polygon" />
                            <p class="tour_desc">시간/요일별 설정 기능이 추가되었어요!</p>
                            <div class="dp_flex flex_end btn_group">
                                <a class="cursor next" @click="onClickTutorial">다음</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ################## 듀토리얼 Dim ################## -->

        <div class="curation_wrap all bg">
            <div class="tab_body">
                <ul class="flex_space_between">
                    <li>
                        <h2 class="curation_time_title">큐레이션 설정</h2>
                    </li>
                    <li v-if="curationSetting.spaceType === 'Store'">
                        <button class="curation_time_btn" @click="onClickTimeSetting">
                            시간대별 설정 &nbsp;
                            <img
                                class="dp_inlineblock curation_time_arrow"
                                src="/media/img/curation/arrow_forward.png"
                                alt="시간대별설정"
                            />
                        </button>
                    </li>
                </ul>
            </div>
            <!--########### Store ###########-->
            <div class="setting_tab1 store" v-if="curationSetting.spaceType === 'Store'">
                <ul class="setting_preferred_country store">
                    <h2 class="curation_item_title">
                        <span class="main_title">국내곡 비율</span>
                        <span class="sub_title" v-if="alertCountry">국내곡 비율은 최대 80%까지 선택 가능합니다.</span>
                    </h2>
                    <ul class="percent_bar">
                        <li
                            class="percent_item"
                            v-for="item in getDomesticRate"
                            :key="item.id"
                            :class="{
                                half: item.value === 0 || item.value === 100,
                                first: item.value === 0,
                                last: item.value === 100,
                                checked: parseInt(curationSetting.domesticRate) > item.value
                            }"
                            @click.prevent="clickCountry(item.value)"
                        >
                            <span
                                v-if="item.value > 0 && item.value < 100 && parseInt(curationSetting.domesticRate) === item.value"
                                class="last_checked"
                            ></span>
                            <input type="checkbox" :id="`domestirate${item.value}`" hidden :value="item.value" />
                            <label v-if="item.value > 0 && item.value < 100" for="percentdot" class="percent-dot"></label>
                        </li>
                    </ul>
                    <ul class="curation_percent">
                        <span class="percent_text">국내 {{ curationSetting.domesticRate }}%</span>
                        <span class="percent_text">해외 {{ 100 - curationSetting.domesticRate }}%</span>
                    </ul>
                </ul>
                <ul class="setting_targets">
                    <h2 class="curation_item_title">주요 고객층</h2>
                    <ul class="target_item_list">
                        <li
                            class="curation_item"
                            v-for="(item, index) in getTarget"
                            :key="item.target1"
                            :class="{ first: index === 0 }"
                        >
                            <label
                                class="setting_item"
                                :class="{ active: target.includes(parseInt(item.value)) || target.includes(item.value) }"
                                :for="item.id"
                            >{{ item.koText }}</label
                            >
                            <input hidden type="checkbox" v-model="target" :value="item.value" :id="item.id" />
                        </li>
                    </ul>
                </ul>
                <ul class="setting_genre">
                    <h2 class="curation_item_title">
                        <span class="main_title">선호 장르(최대 3개)</span>
                        <span v-if="alertCountryTrot" class="sub_title">해외곡만 선호하시는 경우, 트로트는 선택 불가합니다.</span>
                    </h2>
                    <ul class="genre_list">
                        <li class="curation_item first" v-for="(item, index) in getGenre1" :key="item.id">
                            <label
                                class="setting_item"
                                :class="{
                                    active: getIsActive(item),
                                    disabled: parseInt(curationSetting.domesticRate) === 0 && parseInt(item.value) === 10
                                }"
                                :for="'genre' + index + 1"
                                @click="
                                    parseInt(curationSetting.domesticRate) === 0 && parseInt(item.value) === 10
                                        ? () => {}
                                        : clickGenre(item.value, item.koText)
                                "
                            >{{ item.koText }}

                                <img
                                    v-if="getIsShowMoreBtn(item)"
                                    class="settingcuration_modal"
                                    src="/media/img/curation/arrow_forward_black.png"
                                    alt="팝업"
                                />
                            </label>
                            <input hidden type="checkbox" :value="item.value" :id="'genre' + index + 1" />
                        </li>
                    </ul>

                    <ul class="genre_list">
                        <li class="curation_item first" v-for="(item, index) in getGenre2" :key="item.id">
                            <label
                                class="setting_item"
                                :class="{
                                    active: getIsActive(item)
                                }"
                                :for="'genre' + index + 1"
                                @click="clickGenre(item.value, item.koText)"
                            >{{ item.koText }}
                                <img
                                    v-if="getIsShowMoreBtn(item)"
                                    class="settingcuration_modal"
                                    src="/media/img/curation/arrow_forward_black.png"
                                    alt="팝업"
                                />
                            </label>
                            <input hidden type="checkbox" :value="item.value" :id="'genre' + index + 1" />
                        </li>
                    </ul>
                    <ul class="genre_list">
                        <li class="curation_item first" v-for="(item, index) in getGenre3" :key="item.id">
                            <label
                                class="setting_item"
                                :class="{ active: getIsActive(item) }"
                                :for="'genre' + index + 1"
                                @click="clickGenre(item.value, item.koText)"
                            >{{ item.koText }}
                                <img
                                    v-if="getIsShowMoreBtn(item)"
                                    class="settingcuration_modal"
                                    src="/media/img/curation/arrow_forward_black.png"
                                    alt="팝업"
                                />
                            </label>
                            <input hidden type="checkbox" :value="item.value" :id="'genre' + index + 1" />
                        </li>
                    </ul>
                </ul>
                <ul class="setting_peroid">
                    <h2 class="curation_item_title">선호 시대</h2>
                    <ul class="peroid_list" id="peroid">
                        <li
                            class="curation_item first"
                            v-for="(item, index) in getPeroid"
                            :key="item.id"
                            :class="{ first: index === 0 }"
                        >
                            <label
                                class="setting_item"
                                :class="{ active: peroid.includes(parseInt(item.value)) || peroid.includes(item.value) }"
                                :for="`peroid${index + 1}`"
                            >{{ item.text }}</label
                            >
                            <input hidden type="checkbox" v-model="peroid" :value="item.value" :id="`peroid${index + 1}`" />
                        </li>
                    </ul>
                </ul>
                <ul class="setting_colors">
                    <h2 class="curation_item_title">주요 인테리어 색상</h2>
                    <ul class="target_item_list">
                        <li
                            v-for="(item, index) in pcGetColors1"
                            class="curation_item color"
                            :class="{ first: index === 0 }"
                            :key="`color${item.no}`"
                        >
                            <label
                                class="setting_item color"
                                :for="`color${item.no}`"
                                :class="{ active: color === item.no }"
                                @click="clickColor(item.no)"
                            >
                                <img class="color_img" :class="{ white: item.colorNmEn === 'White' }" :src="item.img" />
                                {{ item.colorNm }}
                            </label>
                            <input hidden type="radio" :value="item.no" :id="`color${item.no}`" />
                        </li>
                    </ul>
                </ul>
                <ul class="setting_material">
                    <h2 class="curation_item_title">주요 인테리어 소재(최대3개)</h2>
                    <ul class="target_item_list">
                        <li
                            v-for="(item, index) in pcGetMaterial1"
                            class="curation_item material"
                            :class="{ first: index === 0 }"
                            :key="`material${item.no}`"
                        >
                            <label
                                class="setting_item material"
                                :for="`material${item.no}`"
                                :class="{ active: material.includes(item.no) }"
                                @click="clickMaterial(item.no)"
                            >
                                {{ item.materialNm }}
                            </label>
                            <input hidden type="checkbox" :value="item.no" :id="`material${item.no}`" />
                        </li>
                    </ul>
                </ul>
            </div>
            <!--########### Car ###########-->
            <div class="setting_tab1 car" v-if="curationSetting.spaceType === 'Car'">
                <ul class="setting_car_purpose car">
                    <h2 class="curation_item_title">차량 이용목적</h2>
                    <ul class="car_purpose_list">
                        <li
                            class="curation_item"
                            v-for="(item, index) in carPurpose1"
                            :class="{ first: index === 0 }"
                            :key="item.id"
                        >
                            <label
                                class="setting_item"
                                @click="clickCarPurPose(item.value)"
                                :class="{ active: carPurpose.includes(parseInt(item.value)) || carPurpose.includes(item.value) }"
                                :for="item.id"
                            >{{ item.koText }}</label
                            >
                            <input hidden type="checkbox" :value="item.value" :id="item.id" />
                        </li>
                    </ul>
                    <ul class="car_purpose_list">
                        <li
                            class="curation_item"
                            v-for="(item, index) in carPurpose2"
                            :class="{ first: index === 0 }"
                            :key="item.id"
                        >
                            <label
                                class="setting_item"
                                @click="clickCarPurPose(item.value)"
                                :class="{ active: carPurpose.includes(parseInt(item.value)) || carPurpose.includes(item.value) }"
                                :for="item.id"
                            >{{ item.koText }}</label
                            >
                            <input hidden type="checkbox" :value="item.value" :id="item.id" />
                        </li>
                    </ul>
                </ul>
                <ul class="setting_preferred_country">
                    <h2 class="curation_item_title">
                        <span class="main_title">국내곡 비율</span>
                        <span class="sub_title" v-if="alertCountry">국내곡 비율은 최대 80%까지 선택 가능합니다.</span>
                    </h2>
                    <ul class="percent_bar">
                        <li
                            class="percent_item"
                            v-for="item in getDomesticRate"
                            :key="item.id"
                            :class="{
                                half: item.value === 0 || item.value === 100,
                                first: item.value === 0,
                                last: item.value === 100,
                                checked: parseInt(curationSetting.domesticRate) > item.value
                            }"
                            @click.prevent="clickCountry(item.value)"
                        >
                            <span
                                v-if="item.value > 0 && item.value < 100 && parseInt(curationSetting.domesticRate) === item.value"
                                class="last_checked"
                            ></span>
                            <input type="checkbox" :id="`domestirate${item.value}`" hidden :value="item.value" />
                            <label v-if="item.value > 0 && item.value < 100" for="percentdot" class="percent-dot"></label>
                        </li>
                    </ul>
                    <ul class="curation_percent">
                        <span class="percent_text">국내 {{ curationSetting.domesticRate }}%</span>
                        <span class="percent_text">해외 {{ 100 - curationSetting.domesticRate }}%</span>
                    </ul>
                </ul>
                <ul class="setting_genre">
                    <h2 class="curation_item_title">
                        <span class="main_title">선호 장르(최대 3개)</span>
                        <span v-if="alertCountryTrot" class="sub_title">해외곡만 선호하시는 경우, 트로트는 선택 불가합니다.</span>
                    </h2>
                    <ul class="genre_list">
                        <li class="curation_item first" v-for="(item, index) in getGenre1" :key="item.id">
                            <label
                                class="setting_item"
                                :class="{
                                    active: getIsActive(item),
                                    disabled: parseInt(curationSetting.domesticRate) === 0 && parseInt(item.value) === 10
                                }"
                                :for="'genre' + index + 1"
                                @click="
                                    parseInt(curationSetting.domesticRate) === 0 && parseInt(item.value) === 10
                                        ? () => {}
                                        : clickGenre(item.value, item.koText)
                                "
                            >{{ item.koText }}

                                <img
                                    v-if="getIsShowMoreBtn(item)"
                                    class="settingcuration_modal"
                                    src="/media/img/curation/arrow_forward_black.png"
                                    alt="팝업"
                                />
                            </label>
                            <input hidden type="checkbox" :value="item.value" :id="'genre' + index + 1" />
                        </li>
                    </ul>

                    <ul class="genre_list">
                        <li class="curation_item first" v-for="(item, index) in getGenre2" :key="item.id">
                            <label
                                class="setting_item"
                                :class="{
                                    active: getIsActive(item)
                                }"
                                :for="'genre' + index + 1"
                                @click="clickGenre(item.value, item.koText)"
                            >{{ item.koText }}
                                <img
                                    v-if="getIsShowMoreBtn(item)"
                                    class="settingcuration_modal"
                                    src="/media/img/curation/arrow_forward_black.png"
                                    alt="팝업"
                                />
                            </label>
                            <input hidden type="checkbox" :value="item.value" :id="'genre' + index + 1" />
                        </li>
                    </ul>
                    <ul class="genre_list">
                        <li class="curation_item first" v-for="(item, index) in getGenre3" :key="item.id">
                            <label
                                class="setting_item"
                                :class="{ active: getIsActive(item) }"
                                :for="'genre' + index + 1"
                                @click="clickGenre(item.value, item.koText)"
                            >{{ item.koText }}
                                <img
                                    v-if="getIsShowMoreBtn(item)"
                                    class="settingcuration_modal"
                                    src="/media/img/curation/arrow_forward_black.png"
                                    alt="팝업"
                                />
                            </label>
                            <input hidden type="checkbox" :value="item.value" :id="'genre' + index + 1" />
                        </li>
                    </ul>
                </ul>
                <ul class="setting_peroid">
                    <h2 class="curation_item_title">선호 시대</h2>
                    <ul class="peroid_list" id="peroid">
                        <li
                            class="curation_item first"
                            v-for="(item, index) in getPeroid"
                            :key="item.id"
                            :class="{ first: index === 0 }"
                        >
                            <label
                                class="setting_item"
                                :class="{ active: peroid.includes(parseInt(item.value)) || peroid.includes(item.value) }"
                                :for="`peroid${index + 1}`"
                            >{{ item.text }}</label
                            >
                            <input hidden type="checkbox" v-model="peroid" :value="item.value" :id="`peroid${index + 1}`" />
                        </li>
                    </ul>
                </ul>
            </div>
            <!--########### Tab2 ###########-->
            <div class="tab_bottom">
                <button class="tab_bottom_btn" @click="closeCuration">취소</button>
                <button class="tab_bottom_btn tab_bottom_confirm" :disabled="btnDisabled" @click="validateCuration">
                    적용
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { cloneDeep, isEqual, uniq } from 'lodash';
import { checkInstrumental, curationValidation, isGoFavoriteMusic, retryAutomaticRecomm } from '@/utils/Utils';
import { defineComponent } from '@vue/composition-api';
import {
  updateConfirmCuration,
  updateCurationAllTemp,
  getMoodAndGenre,
  automaticRecommOfPreferMusic
} from '@/service/api/profileApi';
export default defineComponent({
  name: 'SettingCuration',
  components: {
    'vocal-modal': () => import(`@/components/curation/VocalModal.vue`),
    'equalizer-comp': () => import(`@/components/player/Equalizer.vue`),
    'confirm-comp': () => import(`@/components/modal/Space/ConfirmComp.vue`),
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`)
  },
  emits: ['update-curation', 'update-automatic'],
  props: {
    curation: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isshowequalizer: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isNotBasicCuration: false,
      activeTab: 1,
      carMood: -1,
      curationSetting: {},
      moreDetailYn: '',
      alertCountry: false,
      alertCountryTrot: false,
      genre: [],
      genreTuneN: [],
      genreTuneY: [],
      target: [],
      carPurpose: [],
      targetUnits: [],
      color: null,
      peroid: [],
      material: [],
      selectMode: -1,
      originalCurationInfo: {
        targetUnits: [],
        genre: [],
        peroid: [],
        color: null,
        material: []
      },
      isVocalModal: false,
      genreObject: null,
      steps: [
        {
          target: '#target1', // We're using document.querySelector() under the hood
          header: {
            title: 'Get Started'
          },
          content: `Discover <strong>Vue Tour</strong>!`
        }
      ],
      timeCurationStep1: localStorage.getItem('timeCurationStep1') ? localStorage.getItem('timeCurationStep1') : false,
      spaceId: this.$cookies.get('spaceId') ? this.$cookies.get('spaceId') : '',
      isBtnDisabled: false,
      isGenreComp: false,
      tempvalue: null,
      isOverGenreModal: false
    };
  },

  created () {
    this.curationSetting = cloneDeep(this.curation);
    if (
      Object.keys(this.curationSetting).includes('startTime') &&
      Object.keys(this.curationSetting).includes('endTime')
    ) {
      this.isNotBasicCuration = true;
    }

    if (!this.curationSetting.genreTuneY) {
      this.curationSetting.genreTuneY = [];
    }

    if (!this.curationSetting.genreTuneN) {
      this.curationSetting.genreTuneN = [];
    }

    this.setGenre();
    this.setPeroid();
    this.setGenreTune(true);
    this.setGenreTune(false);

    if (this.curationSetting.spaceType === 'Store') {
      this.setTarget();
      this.setColor();
      this.setTargetUnits();
      this.setMaterial();
    } else if (this.curationSetting.spaceType === 'Car') {
      //
      this.setCarPurpose();
      this.setCarMood();
    }
    this.settingOriginalCuration();
  },

  mounted () {},

  methods: {
    onCloseOverGenreModal () {
      this.isOverGenreModal = false;
    },
    onCloseGenreModal () {
      this.tempvalue = null;
      this.isGenreComp = false;
    },
    onConfirmGenreModal () {
      this.isGenreComp = false;
      this.pushGenre(this.tempvalue.val, this.tempvalue.koText);
      this.tempvalue = null;
    },
    onClickTimeSetting () {
      this.$router.push({ path: `/curationsettingtime/${this.spaceId}?fromUrl=PlayerView` }).catch(() => {});
    },
    onClickTutorial () {
      this.timeCurationStep1 = true;
      localStorage.setItem('timeCurationStep1', this.timeCurationStep1);
      this.$router.push({ path: `/curationsetting/${this.spaceId}?type=new` }).catch(() => {});
    },
    onConfirmVocal (val) {
      if (val) {
        const { genrevalue, selectVocalValue } = val;
        if (selectVocalValue === 0) {
          this.genreTuneN.push(genrevalue);
        } else if (selectVocalValue === 1) {
          this.genreTuneY.push(genrevalue);
        } else if (selectVocalValue === 2) {
          this.genre.push(genrevalue);
        }
        this.isVocalModal = false;
      }
    },
    onCloseVocalModal () {
      this.isVocalModal = false;
      this.genreObject = null;
    },
    getVocalText (item) {
      const { value } = item;
      if (this.genre && this.genre.includes(parseInt(value))) {
        return '상관없음';
      }

      if (this.genreTuneN && this.genreTuneN.includes(parseInt(value))) {
        return '보컬있음';
      }

      if (this.genreTuneY && this.genreTuneY.includes(parseInt(value))) {
        return '보컬없음';
      }

      return '';
    },
    getIsActive (item) {
      const { value } = item;
      if (parseInt(value) === 5 || parseInt(value) === 11) {
        if (this.genre && this.genre.includes(parseInt(value))) {
          return true;
        }

        if (this.genreTuneN && this.genreTuneN.includes(parseInt(value))) {
          return true;
        }

        if (this.genreTuneY && this.genreTuneY.includes(parseInt(value))) {
          return true;
        }
      }
      return this.genre.includes(parseInt(value)) || this.genre.includes(value);
    },
    getIsShowMoreBtn (item) {
      const { value } = item;
      if (parseInt(value) === 5 || parseInt(value) === 11) {
        return true;
      }
      return false;
    },
    clickCarPurPose (value) {
      this.carPurpose = [value];
    },
    onClickOutside () {
      this.$store.commit('player/setIsOpenCurationSetting', false);
    },
    validateCuration () {
      let genre = [];

      if (this.genre.length > 0) {
        genre = [...this.genre];
      }

      if (this.genreTuneN.length > 0) {
        genre = [...genre, ...this.genreTuneN];
      }

      if (this.genreTuneY.length > 0) {
        genre = [...genre, ...this.genreTuneY];
      }
      const result = curationValidation(this.curationSetting, genre, this.peroid);

      const { isAlert, text } = result;
      if (isAlert) {
        this.$store.commit('player/setIsAlertModal', isAlert);
        this.$store.commit('player/setAlertText', text);
        return false;
      }

      if (genre.length > 3) {
        this.isOverGenreModal = true;
        return false;
      }

      // update curation
      this.updateCuration();
    },
    async updateCuration () {
      this.isBtnDisabled = true;
      const spaceId = this.$cookies.get('spaceId');

      const carMood = this.curationSetting.spaceType === 'Car' ? parseInt(this.carMood) : null;
      const carPurpose =
        this.curationSetting.spaceType === 'Car' ? this.carPurpose.sort((a, b) => a - b).join(',') : null;
      const curationInfo = {
        spaceId,
        mood: this.curationSetting.spaceType === 'Store' ? this.selectMode : null,
        carMood,
        carPurpose,
        domesticRate: this.curationSetting.domesticRate,
        foreignRate: String(100 - parseInt(this.curationSetting.domesticRate)),
        genre: this.genre.sort((a, b) => a - b).join(','),
        genreTuneN: this.genreTuneN.sort((a, b) => a - b).join(','),
        genreTuneY: this.genreTuneY.sort((a, b) => a - b).join(','),
        period: this.peroid.sort((a, b) => a - b).join(',')
      };
      if (this.curationSetting.spaceType === 'Store') {
        curationInfo.target = this.target.sort((a, b) => a - b).join(',');
        curationInfo.targetUnit = this.targetUnits.sort((a, b) => a - b).join(',');
        curationInfo.color = this.color;
        curationInfo.material = this.material.sort((a, b) => a - b).join(',');

        this.updateStoreCuration(curationInfo);
      } else {
        try {
          const { genre, genreTuneN, genreTuneY } = curationInfo;
          let genreConcat = [];
          if (genre) {
            genreConcat = [...genre.split(',')];
          }

          if (genreTuneN) {
            genreConcat = [...genreConcat, ...genreTuneN.split(',')];
          }

          if (genreTuneY) {
            genreConcat = [...genreConcat, ...genreTuneY.split(',')];
          }
          const set = new Set(genreConcat);
          const isGoFavorite = isGoFavoriteMusic(Array.from(set).map(item => parseInt(item)));
          const { data: curationTempResult } = await updateCurationAllTemp(curationInfo);
          const { resultCd: curationTempResultCd } = curationTempResult;
          if (curationTempResultCd === '0000') {
            localStorage.setItem('spaceId', spaceId);
            if (isGoFavorite) {
              this.$emit('update-curation', { curationInfo });
              return;
            }

            const { data: updateCurationData } = await updateConfirmCuration(spaceId, this.curation.curationId);
            const { resultCd: updateCd } = updateCurationData;
            if (updateCd === '0000') {
              const { data: recommPreferData } = await automaticRecommOfPreferMusic(this.curation.curationId);
              const { resultCd: recommPreferResultCd } = recommPreferData;
              if (recommPreferResultCd !== '0000') return false;
              this.$emit('update-automatic', { curationInfo });
            } else {
              this.failUpdateCuration();
            }
          }
        } catch (error) {
          console.error('updateCuration error : ', error);
          this.failUpdateCuration();
        } finally {
          this.isBtnDisabled = false;
        }
      }
    },
    setCarPurpose () {
      this.carPurpose = this.curationSetting.carPurpose.map(item => this.$store.getters['player/getCarPurpose'](item));
    },
    setCarMood () {
      this.carMood = this.$store.getters['player/getCarMode'](this.curationSetting.carMood);
    },
    settingCurationValue () {
      let array = [];
      let genreArray = [];
      // 매장
      if (this.curationSetting.spaceType === 'Store') {
        this.target.forEach(val => {
          const item = this.$store.getters['player/getTargetUnitFilter'](parseInt(val));
          array = [...array, ...item];
        });
        const set = new Set(array);
        this.targetUnits = Array.from(set);
      } else if (this.curationSetting.spaceType === 'Car') {
        // 차량
        this.carPurpose.forEach(val => {
          const item = this.$store.getters['player/getCarGenreFilter'](parseInt(val));
          genreArray = [...genreArray, ...item];
        });
        const genreSet = new Set(genreArray);
        this.genre = Array.from(genreSet).sort((a, b) => a - b);
        if (this.carPurpose.length > 1) {
          const sort = this.carPurpose.sort((a, b) => a - b);
          this.carMood = this.$store.getters['player/getCarMoodFilter'](parseInt(sort[0]));
        } else {
          this.carMood = this.$store.getters['player/getCarMoodFilter'](parseInt(this.carPurpose[0]));
        }
      }
    },
    async updateStoreCuration (curationInfo) {
      const spaceId = this.$cookies.get('spaceId');
      try {
        localStorage.setItem('spaceId', spaceId);
        const resultMoodEmotion = await this.getMoodEmotion(this.color, this.material.sort((a, b) => a - b).join(','));
        let targetUnit = '';
        // targetUnit
        if (this.target) {
          let array = [];
          this.target.forEach(val => {
            const item = this.$store.getters['player/getTargetUnitFilter'](val);
            array = [...array, ...item];
          });
          const set = new Set(array);
          targetUnit = Array.from(set).join(',');
        }
        const { genre, genreTuneN, genreTuneY } = curationInfo;
        let genreConcat = [];
        if (genre) {
          genreConcat = [...genre.split(',')];
        }

        if (genreTuneN) {
          genreConcat = [...genreConcat, ...genreTuneN.split(',')];
        }

        if (genreTuneY) {
          genreConcat = [...genreConcat, ...genreTuneY.split(',')];
        }
        const set = new Set(genreConcat);
        const isGoFavorite = isGoFavoriteMusic(Array.from(set).map(item => parseInt(item)));
        const { data: curationTempData } = await updateCurationAllTemp({
          spaceId: this.spaceId,
          mood: resultMoodEmotion.mood,
          emotion: resultMoodEmotion.emotion,
          domesticRate: this.curationSetting.domesticRate,
          foreignRate: String(100 - parseInt(this.curationSetting.domesticRate)),
          genre: this.genre.sort((a, b) => a - b).join(','),
          genreTuneN: this.genreTuneN.sort((a, b) => a - b).join(','),
          genreTuneY: this.genreTuneY.sort((a, b) => a - b).join(','),
          period: this.peroid.sort((a, b) => a - b).join(','),
          curationId: this.curation.curationId,
          target: this.target.sort((a, b) => a - b).join(','),
          targetUnit,
          color: this.color,
          material: this.material.sort((a, b) => a - b).join(',')
        });

        const { resultCd: curationTempResultCd } = curationTempData;
        if (curationTempResultCd === '0000') {
          if (isGoFavorite) {
            this.$emit('update-curation', { curationInfo });
          } else {
            await updateConfirmCuration(this.spaceId, this.curation.curationId);
            const { data: recommPreferData } = await automaticRecommOfPreferMusic(this.curation.curationId);
            const { resultCd: recommPreferResultCd } = recommPreferData;
            if (recommPreferResultCd !== '0000') return false;
            this.$emit('update-automatic', { curationInfo });
          }
        } else {
          this.failUpdateCuration();
        }
      } catch (error) {
        if (error.response) {
          const { url } = error.response.config;
          if (url === '/api/profile/automaticRecommOfPreferMusic') {
            console.log(error.response);
            retryAutomaticRecomm(
              0,
              () => {
                this.isBtnDisabled = false;
              },
              () => {
                this.isBtnDisabled = false;
              }
            );
          }
        }
        console.error('updateStoreCuration error : ', error);
      } finally {
        this.isBtnDisabled = false;
      }
    },
    async getMoodEmotion (color, material) {
      try {
        const { data } = await getMoodAndGenre(color, material);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          const emotionlist = [];
          const { mood, emotion: returnEmotion } = result;
          returnEmotion.forEach(item => {
            const findItem = this.$store.getters['curation/getEmotionValue'](item);
            if (findItem) {
              emotionlist.push(parseInt(findItem.value));
            }
          });
          return {
            mood,
            emotion: emotionlist.sort((a, b) => a - b).join(',')
          };
        }
      } catch (error) {
        //
        return {
          mood: '',
          emotion: ''
        };
      }
    },
    failUpdateCuration () {
      alert('선호음악 선정에 실패하였습니다.');
    },
    settingOriginalCuration () {
      if (this.curationSetting.spaceType === 'Store') {
        this.originalCurationInfo.targetUnits = this.targetUnits.sort((a, b) => a - b);
      }
      this.originalCurationInfo.genre = this.genre.sort((a, b) => a - b);
      this.originalCurationInfo.peroid = this.peroid.sort((a, b) => a - b);
    },
    diffCuration () {
      this.peroid = this.peroid.map(item => parseInt(item)).sort((a, b) => a - b);
      this.genre = this.genre.map(item => parseInt(item)).sort((a, b) => a - b);

      const peroidEqual = isEqual(this.peroid, this.originalCurationInfo.peroid);
      const genreEqual = isEqual(this.genre, this.originalCurationInfo.genre);
      if (this.curationSetting.spaceType === 'Store') {
        this.targetUnits = this.targetUnits.map(item => parseInt(item)).sort((a, b) => a - b);
        const targetUnitsEqual = isEqual(this.targetUnits, this.originalCurationInfo.targetUnits);
        if (!targetUnitsEqual || !peroidEqual || !genreEqual) {
          return true;
        }
        return false;
      } else {
        if (!peroidEqual || !genreEqual) {
          return true;
        }
        return false;
      }
    },
    setGenre () {
      if (this.curationSetting.genre) {
        this.genre = this.curationSetting.genre.map(item => this.$store.getters['player/getCurationStoreGenre'](item));
      } else {
        this.genre = [];
      }
    },
    setPeroid () {
      this.peroid = this.curationSetting.period.map(item => this.$store.getters['player/getCurationStorePeroid'](item));
    },
    setGenreTune (isVocal = false) {
      if (isVocal) {
        if (this.curationSetting.genreTuneY) {
          this.curationSetting.genreTuneY.forEach(ele => {
            const find = this.$store.getters['curation/getGenreValue'](ele);
            if (find) {
              this.genreTuneY.push(parseInt(find.value));
            }
          });
        }
      } else {
        this.curationSetting.genreTuneN.forEach(ele => {
          const find = this.$store.getters['curation/getGenreValue'](ele);
          if (find) {
            this.genreTuneN.push(parseInt(find.value));
          }
        });
      }
    },
    setTargetUnits () {
      this.targetUnits = this.curationSetting.targetUnit.map(item =>
        this.$store.getters['player/getCurationStoreTargetUnits'](item)
      );
    },
    setMaterial () {
      if (this.curationSetting.material) {
        this.material = this.curationSetting.material.map(
          item => this.$store.getters['curation/getMaterialValue'](item).no
        );
      }
    },
    setTarget () {
      this.curationSetting.target.forEach(item => {
        const find = this.$store.getters['curation/getTargetValue'](item);
        if (find) {
          this.target.push(parseInt(find.value));
        }
      });
    },
    setColor () {
      if (this.curationSetting.spaceType === 'Store') {
        if (this.curationSetting.color) {
          const findColor = this.$store.getters['curation/getColorItem'](this.curationSetting.color);
          if (Object.keys(findColor).length > 0) {
            this.color = findColor.no;
          }
        }
      }
    },
    clickColor (val) {
      if (val === this.color) {
        this.color = null;
        return false;
      }
      this.color = parseInt(val);
    },
    clickMaterial (val) {
      const findIndex = this.material.findIndex(findItem => findItem === val);
      if (findIndex > -1) {
        this.material.splice(findIndex, 1);
      } else {
        if (this.material.length < 3) {
          this.material.push(val);
        }
      }
    },
    clickCountry (value) {
      if (value > 80) {
        this.alertCountryTrot = false;
        this.alertCountry = true;
        return false;
      }
      this.alertCountryTrot = false;
      this.alertCountry = false;
      this.curationSetting.domesticRate = value;

      const idx = this.genre.findIndex(item => parseInt(item) === 10);
      if (parseInt(value) === 0 && idx > -1) {
        this.genre = this.genre.filter(el => el !== 10); // 트로트 장르 빼기
        this.alertCountryTrot = true;
      }

      if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length > 0) {
        this.alertCountryTrot = false;
      } else if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length === 0) {
        this.alertCountryTrot = true;
      }
    },
    closeCuration () {
      this.onClickOutside();
    },
    pushGenre (val, koText) {
      const total = this.genre.length + this.genreTuneY.length + this.genreTuneN.length;

      if (this.isVocalGenre(val)) {
        // 활성화 되어있는 경우
        const findGenreIndex = this.genre.findIndex(item => item === parseInt(val));
        const findGenreTuneYIndex = this.genreTuneY.findIndex(item => item === parseInt(val));
        const findGenreTuneNIndex = this.genreTuneN.findIndex(item => item === parseInt(val));
        if (findGenreIndex > -1 || findGenreTuneYIndex > -1 || findGenreTuneNIndex > -1) {
          if (findGenreIndex > -1) this.genre.splice(findGenreIndex, 1);
          if (findGenreTuneYIndex > -1) this.genreTuneY.splice(findGenreTuneYIndex, 1);
          if (findGenreTuneNIndex > -1) this.genreTuneN.splice(findGenreTuneNIndex, 1);
        } else {
          // 활성화 되어있지 않는 경우 - 모달띄우기
          if (total < 3) {
            this.genreObject = {
              genrename: koText,
              genrevalue: parseInt(val)
            };
            this.isVocalModal = true;
          } else {
            this.isOverGenreModal = true;
          }
        }
      } else {
        const findIndex = this.genre.findIndex(item => item === parseInt(val));

        if (findIndex > -1) {
          this.genre.splice(findIndex, 1);
        } else {
          if (total < 3) {
            this.genre.push(parseInt(val));
          } else {
            this.isOverGenreModal = true;
            return true;
          }
        }

        if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length > 0) {
          this.alertCountryTrot = false;
        } else if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length === 0) {
          this.alertCountryTrot = true;
        }
      }
    },
    clickGenre (val, koText) {
      const genre = uniq([...this.genre, ...this.genreTuneY, ...this.genreTuneN]);

      if (genre.length < 3) {
        if (checkInstrumental(genre, val)) {
          this.tempvalue = {
            val,
            koText
          };
          this.isGenreComp = true;
          return true;
        }
      }

      this.pushGenre(val, koText);
    },
    isVocalGenre (val) {
      // 재즈,클래식
      const vocalList = [5, 11];
      if (vocalList.includes(parseInt(val))) {
        return true;
      }

      return false;
    }
  },
  computed: {
    getEmotion1 () {
      const clone = cloneDeep(this.$store.getters['curation/getEmotion']);
      return clone.slice(0, 6);
    },
    getEmotion2 () {
      const clone = cloneDeep(this.$store.getters['curation/getEmotion']);
      return clone.slice(6, 10);
    },
    getPeroid () {
      return this.$store.getters['curation/getPeroid'];
    },
    getGenre1 () {
      const clone = cloneDeep(this.$store.getters['curation/getGenreAll']);

      return [clone[0], clone[1], clone[2], clone[3], clone[8]];
    },
    getGenre2 () {
      const clone = cloneDeep(this.$store.getters['curation/getGenreAll']);
      return [clone[5], clone[6], clone[7], clone[10]];
    },
    getGenre3 () {
      const clone = cloneDeep(this.$store.getters['curation/getGenreAll']);
      const jazzVocal = this.getVocalText(clone[4]);
      const classicVocal = this.getVocalText(clone[9]);
      if (jazzVocal) {
        clone[4].koText = clone[4].koText + '-' + jazzVocal;
      }

      if (classicVocal) {
        clone[9].koText = clone[9].koText + '-' + classicVocal;
      }
      return [clone[4], clone[9]];
    },
    getTargetUnit () {
      return this.$store.getters['curation/getTargetUnit'];
    },
    getDomesticRate () {
      return this.$store.getters['curation/getDomesticRate'];
    },
    getTarget () {
      return this.$store.getters['curation/getTarget'];
    },
    pcGetColors1 () {
      return this.$store.getters['curation/getCurationColors'];
    },
    pcGetColors2 () {
      return this.$store.getters['curation/getCurationColors'].slice(8, 16);
    },
    pcGetMaterial1 () {
      return this.$store.getters['curation/getCurationMaterial'];
    },
    carPurpose1 () {
      return this.$store.getters['curation/getCarPurpose'].slice(0, 6);
    },
    carPurpose2 () {
      return this.$store.getters['curation/getCarPurpose'].slice(6, 7);
    },
    btnDisabled () {
      const { spaceType } = this.curationSetting;
      if (!this.isBtnDisabled) {
        if (
          parseInt(this.curationSetting.domesticRate) === 0 &&
          (this.genre.includes(10) || this.genre.includes('10'))
        ) {
          return true;
        } else if (spaceType === 'Store') {
          if (
            this.targetUnits.length === 0 ||
            this.target.length === 0 ||
            this.color === null ||
            this.peroid.length === 0 ||
            (this.genre.length === 0 && this.genreTuneN.length === 0 && this.genreTuneY.length === 0) ||
            this.material.length < 1 ||
            this.material.length > 3
          ) {
            return true;
          }
        } else if (spaceType === 'Car') {
          if (
            this.carPurpose.length === 0 ||
            this.peroid.length === 0 ||
            (this.genre.length === 0 && this.genreTuneN.length === 0 && this.genreTuneY.length === 0)
          ) {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return this.isBtnDisabled;
      }

      return false;
    }
  }
});
</script>
<style scoped src="@/assets/css/player/settingcuration.css"></style>
